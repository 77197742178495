<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Search Company</h4>
    <button class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="widgets">
      <div class="row">
        <div class="col-md-12">
          <div>
            <ba-card baCardClass="with-scroll">
              <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="btn-group" role="group">
                        <div class="top-control input-group">
                            <input type="text" class="form-control with-danger-addon" placeholder="Search for..." #searchQuery>
                            <span class="input-group-btn">
                                <button class="btn btn-danger" type="button" (click)="search()"><i class="ion-search"></i></button>
                            </span>
                        </div>
                    </div>
              </div>
              <div class="feed-messages-container">
                  <div class="feed-message" *ngFor="let company of assignedCompany">
                      <i style="cursor: pointer;" class="add-item-icon ion-checkmark-round pull-right" (click)="onSelectClick(company.id, company.logo, company.name)"></i>
                      <div class="message-icon" lazy-load-images>
                          <!-- <img class="photo-icon" src="{{ company.logo == null? 'assets/img/theme/no-logo.jpg' : company.logo }}"> -->
                          <img *ngIf="company.logo == null" class="photo-icon" src="{{ 'assets/img/theme/no-logo.jpg' }}">
                          <img *ngIf="company.logo != null" class="photo-icon" [attr.data-src]="myApiServerURL + '/companyLogo?file_name=' + company.logo">
                          <!-- <img *ngIf="company.logo != null" class="photo-icon" [attr.data-src]="myApiServerURL + '/company/logo/' + company.logo"> -->
                      </div>
                      <div class="text-block text-message" (click)="expandCompany(company)">
                          <div class="message-header">
                              <span class="author">{{ company.name }}</span>
                          </div>
                          <div class="message-content line-clamp" [ngClass]="{'line-clamp-2' : !company.expanded}">
                              {{ company.office_address }}
                          </div>
                      </div>
                  </div>
              </div>
            </ba-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>