<div baCardBlur class="animated fadeIn card {{cardType}} {{baCardClass || ''}}" zoom-in>
    <div *ngIf="cardTitle" class="card-header clearfix">
        <h3 class="card-title" translate>
            {{cardTitle}}
            <i style="cursor: pointer;" class="add-item-icon ion-plus-round pull-right" *ngIf="hasAddButton" (click)="onAddClick()"></i>
        </h3>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
