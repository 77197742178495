<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
  <a routerLink="/pages/dashboard" class="al-logo clearfix"><span>Pay</span>book</a>
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon"></a>
  <a class="navbar-brand" >{{ companyName }}</a>

  <!--<div class="search">
    <i class="ion-ios-search-strong" ng-click="startSearch()"></i>
    <input id="searchInput" type="text" placeholder="Search for...">
  </div>-->

  <div class="user-profile clearfix">
    <div class="dropdown al-user-profile">
      <a class="profile-toggle-link dropdown-toggle" id="user-profile-dd" data-toggle="dropdown" aria-expanded="false">
        <!-- <img src="{{ ( 'Nasta' | baProfilePicture ) }}"> -->
        <!-- <img class="photo-icon" src="{{ companyLogo == null? 'assets/img/theme/no-logo.jpg' : companyLogo }}"> -->
        <img *ngIf="companyLogo == null" class="photo-icon" src="{{ 'assets/img/theme/no-logo.jpg' }}">
        <img *ngIf="companyLogo != null" class="photo-icon" src="{{ myApiServerURL + '/companyLogo?file_name=' + companyLogo }}">
      </a>
      <ul class="dropdown-menu top-dropdown-menu profile-dropdown" aria-labelledby="user-profile-dd">
        <li class="dropdown-item"><a style="cursor: pointer;" (click)="selectCompany()"><i class="fa fa-cog"></i>Select Company</a></li>
        <li class="dropdown-item"><a style="cursor: pointer;" (click)="userProfile()"><i class="fa fa-user"></i>My Profile</a></li>
        <li class="dropdown-item"><a style="cursor: pointer;" class="signout" (click)="signOut()"><i class="fa fa-power-off"></i>Sign out</a></li>
      </ul>
    </div>
    <!--<ba-msg-center></ba-msg-center>-->
  </div>
</div>


